<template>
  <div v-if="isShowSoundController"
       id="bodyTopMainUserCasePopupSoundController">
    <div :class="soundController.soundBtnWrapper"
         class="absolute-center z-top q-pb-lg">
      <q-btn :class="soundController.soundBtn"
             :ripple="false"
             class="relative-position"
             flat
             rounded
             @click="onToggleHandler">
        <div class="row justify-center items-center">
          <q-img :class="soundController.soundImg"
                 :src="!isMuted
                  ? require('@/assets/images/img_userCase_sound_on.png')
                  : require('@/assets/images/img_userCase_sound_off.png')"
                 height="22px"
                 no-spinner
                 width="26px"/>
        </div>

      </q-btn>
    </div>
  </div>
</template>

<script setup>
// Imports

import { computed, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const isMuted = ref(true)

// Watches

// Methods

const onToggleHandler = () => {
  isMuted.value = !isMuted.value
  store.commit('setUserCaseSound', isMuted.value)
}

const isShowSoundController = reactive(computed(() => store.getters.getShowSoundController))
const userPlatform = reactive(computed(() => store.getters.getUserPlatform))

// Lifecycles

onMounted(() => {
  if (userPlatform.value !== 'mobile') {
    isMuted.value = false
    return ''
  }
  isMuted.value = true
})

onUnmounted(() => {
  isMuted.value = true
})

</script>

<style module="soundController">
.soundBtnWrapper {
  top: 90.8% !important;
}

.soundBtn {
  padding: 16px 24px !important;
  background-color: rgba(102, 102, 102, .5) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(102, 102, 102, 0);
}

.soundImg {
  width: 24px;
  height: 24px;
}

.text {
  padding: 6px 0 0 3px;
}
</style>
