import { gsap } from '@/assets/modules/gsap.min'

export const eqPopupOpenTween = (el) => {
  return gsap.to(el, {
    position: 'absolute',
    delay: 0,
    duration: 0,
    width: 280,
    height: 480,
    borderRadius: '24px',
    onComplete: () => {
      return gsap.to('#userCaseVideo', 0.5, {
        opacity: 1
      })
    }
  })
}

export const eqPopupCloseTween = (el, emit) => {
  return gsap.to(el, {
    position: 'absolute',
    delay: 0,
    scale: 1,
    duration: 0,
    width: 224,
    height: 480,
    opacity: 0,
    borderRadius: '112px',
    onUpdate: () => {
      setTimeout(() => emit('closePopup'), 150)
    }
  })
}
