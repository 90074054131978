<template>
  <q-card
    id="eqWrapper"
    class="eqWrapper no-scroll overflow-hidden hide-scrollbar"
    flat
    onmouseover="this.style.cursor='pointer'"
  >
    <body-top-main-user-case-popup-replay/>
    <body-top-main-user-case-popup-sound-controller :platform="$attrs.platform"/>
    <body-top-main-user-case-popup-content :headers="$attrs.headers"
                                           :isShow="isShowContents"
                                           :messages="$attrs.messages"
                                           :platform="$attrs.platform"
                                           :selectedJobIndex="$attrs.selectedJobIndex"
                                           @click="closePopup"/>
  </q-card>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { eqPopupOpenTween, eqPopupCloseTween } from '@/utils/eqPopupTween'
import { onMounted, ref, onUnmounted, defineEmits } from 'vue'
import BodyTopMainUserCasePopupContent from '@/components/BodyTopMainUserCasePopupContent'
import BodyTopMainUserCasePopupSoundController from '@/components/BodyTopMainUserCasePopupSoundController'
import BodyTopMainUserCasePopupReplay from '@/components/BodyTopMainUserCasePopupReplay'

// Props

// Emits

// Variables

const store = useStore()
const emit = defineEmits()

// Responses

const isShowContents = ref(true)

// Methods

const closePopup = () => {
  const el = document.querySelector('.eqWrapper')
  isShowContents.value = false
  store.commit('setUserCaseSound', true)
  store.commit('setShowVideoReplay', false)
  store.commit('setMouseEnableOnUserCases', true)
  eqPopupCloseTween(el, emit)
}

// Lifecycles

onMounted(() => {
  const el = document.querySelector('.eqWrapper')
  eqPopupOpenTween(el)
  store.commit('setShowSoundController', true)
})

onUnmounted(() => {
  emit('closePopup')
  store.commit('setMouseEnableOnUserCases', true)
  store.commit('setShowSoundController', true)
})

</script>

<style module="userCasePopup">
.eqContainer {
  position: absolute !important;
  width: 224px;
  height: 480px;
  min-height: 480px;
  border-radius: 24px !important;
}

.eqWrapper {
  border-radius: 24px !important;
}

</style>
