<template>
  <div v-if="$attrs.isShow"
       id="BodyTopMainUserCasePopupContent"
       class="no-scroll overflow-hidden hide-scrollbar">
    <q-toolbar :class="userCasePopupContent.layout"
               class="bg-color_white no-scroll hide-scrollbar overflow-hidden relative-position">
      <div :class="userCasePopupContent.logo">
        <q-img no-spinner no-transition src="@/assets/images/img_userCasePopupContent_logo.png"
               width="100%"/>
      </div>
      <div class="column justify-center q-pl-sm">
        <q-toolbar-title class="text-body_small">
          {{ $attrs.headers[parseInt($attrs.selectedJobIndex) - 1] }}
        </q-toolbar-title>
      </div>
      <q-space/>
      <q-btn :class="userCasePopupContent.closeBtn" flat>
        <q-img src="@/assets/images/img_close.png"></q-img>
      </q-btn>
    </q-toolbar>
    <q-separator color="grey-3"/>
    <div class="videoWrapper row justify-center items-center text-color_lightgray2 no-scroll overflow-hidden hide-scrollbar">
      <video id="userCaseVideo"
             :class="userCasePopupContent.video"
             :muted="isMuted"
             autoplay
             playsinline
      >
        <source :src="require(`@/assets/video/vid_top_case${$attrs.selectedJobIndex}.mp4`)"
                type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { computed, onMounted, reactive } from 'vue'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const isMuted = reactive(computed(() => store.getters.getIsUserCasePopupMuted))
const userPlatform = reactive(computed(() => store.getters.getUserPlatform))

// Methods

// Lifecycles
onMounted(() => {
  const video = document.getElementById('userCaseVideo')
  video.addEventListener('ended', () => {
    store.commit('setShowSoundController', false)
    store.commit('setShowVideoReplay', true)
  })

  if (userPlatform.value !== 'mobile') {
    store.commit('setUserCaseSound', false)
    return ''
  }
  store.commit('setUserCaseSound', true)
})

</script>

<style module="userCasePopupContent">

.layout {
  height: 64px;
  padding: 0 18px !important;
}

.logo {
  width: 32px;
  height: 32px;
}

.video {
  width: 100%;
  max-width: 310px;
  border-radius: 24px !important;
}

.closeBtn {
  width: 100% !important;
  max-width: 36px !important;
  height: 100%;
  max-height: 36px !important;
  padding: 6px !important;
}

</style>
