<template>
  <div id="videoReplay">
    <div v-if="isShowButton"
         :class="videoReplay.replayBtnWrapper"
         class="absolute-center z-top q-pb-lg">
      <q-btn :class="videoReplay.replayBtn"
             :ripple="false"
             class="row justify-center items-center relative-position"
             flat
             rounded
             @click="onStartVideo">
        <div class="row justify-center items-center q-gutter-sm">
          <q-img :class="videoReplay.replayImg"
                 name="replay"
                 src="@/assets/images/img_replay.png"/>
        </div>
      </q-btn>
    </div>
  </div>
</template>

<script setup>
// Imports

import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
// Props

// Emits

// Variables

const store = useStore()

// Responses

const isShowButton = reactive(computed(() => store.getters.getShowVideoReplay))

// Watches

// Methods

const onStartVideo = () => {
  const video = document.getElementById('userCaseVideo')
  video.currentTime = 0
  video.play()
  store.commit('setShowVideoReplay', false)
  store.commit('setShowSoundController', true)
}

// Lifecycles

</script>

<style module="videoReplay">

.replayBtnWrapper {
  top: 90.8% !important;
}

.replayBtn {
  padding: 15px 25px !important;
  background-color: #2E67FE !important;
}

.replayImg {
  width: 24px !important;
  height: 24px !important;
}

.text {
  padding: 6px 0 0 3px;
}
</style>
